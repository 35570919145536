
// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ORIGIN LABEL --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const originLabel = "Happ'In Co";

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DISCOVIA COLORS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const yellowLight = '#FDCE00';
export const yellowMain = '#F5B200';
export const yellowDark = '#CF9700';

export const magentaLight = '#D371AB';
export const magentaMain = '#AC0066';
export const magentaDark = '#890F61';

export const blueLight = '#3491D4';
export const blueMain = '#086CBA';
export const blueDark = '#2F206B';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MUI COLORS --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const successMain = '#2e7d32';
export const warningMain = '#ed6c02';
export const errorMain = '#d32f2f';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- FORBIDDEN KEYS NUMBER INPUT ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const forbiddenKeysNumberInput = ['-', '+', 'e'];

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORBIDDEN KEYS EXCEL --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const forbiddenKeysExcel = ['*', '?', ':', '/', '\'', '[', ']'];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EMAIL PATTERN ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

// eslint-disable-next-line
export const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
