import { Suspense, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Language as LocaleIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';
import { getLocales, handleLocaleManagement } from 'services/helpers';
import i18next from 'i18next';

// Components
import Header from 'components/header';
import Footer from 'components/footer';
import Spinner from 'components/spinner';

// CSS
import styles from './Unauthenticated.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- AUTHENTICATED LAYOUT -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AuthenticatedLayout() {

    const locale = localStorage.getItem('locale');
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    useEffect(() => {
        if (locale) {
            handleLocaleManagement(locale);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header authenticated={false} />
            <div className={styles.container}>
                {mediaMD && (
                    <div className={styles.content}>
                        {/* -------------------- BG INTERNSHIP -------------------- */}
                        <div className={`${styles.background} ${styles.backgroundInternship}`}>
                            <div className={styles.title}>
                                {t('internship_monitoring')}
                            </div>
                        </div>
                        {/* -------------------- BG QUESTIONNAIRE -------------------- */}
                        <div className={`${styles.background} ${styles.backgroundQuestionnaire}`}>
                            <div className={styles.title}>
                                {t('creation_and_sending_of_questionnaire_at_trainee_and_tutor')}
                            </div>
                        </div>
                        {/* -------------------- BG ATTENDANCE -------------------- */}
                        <div className={`${styles.background} ${styles.backgroundAttendance}`}>
                            <div className={styles.title}>
                                {t('attendances_management')}
                            </div>
                        </div>
                        {/* -------------------- LINK -------------------- */}
                        <div className={styles.link}>
                            <a
                                href="https://discovia.fr"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.linkItem}
                            >
                                Discovia
                            </a>
                            <a
                                href="https://guide.discovia.fr?applicationId=2"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.linkItem}
                                style={{ marginLeft: 8 }}
                            >
                                {t('user_guide')}
                            </a>
                        </div>
                    </div>
                )}
                <div className={styles.outlet}>
                    <Locale />
                    <Suspense fallback={<Spinner />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
            <Footer authenticated={false} />
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LOCALE ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function Locale() {

    const locales = getLocales().filter(l => !l.value.includes('-'));
    const { mediaMD } = useMediaQueries();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onChangeLocale = (locale: string) => {
        handleLocaleManagement(locale);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={e => setAnchorEl(e.currentTarget)}
                className={styles.locale}
                sx={{ color: mediaMD ? undefined : 'white' }}
            >
                <LocaleIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '&& .Mui-selected': {
                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        ':hover': { backgroundColor: 'rgba(255, 0, 0, 0.1)' },
                    },
                }}
            >
                {locales.map(locale => (
                    <MenuItem
                        key={locale.value}
                        onClick={() => onChangeLocale(locale.value)}
                        selected={i18next.language === locale.value}
                    >
                        {locale.notTranslated}
                    </MenuItem> 
                ))}
            </Menu>
        </>
    );
}
