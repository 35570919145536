import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import i18next from 'i18next';

// Notistack
import { SnackbarProvider } from 'notistack';

// Dayjs Locale
import 'dayjs/locale/fr';
import 'dayjs/locale/de';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo-config';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// React International Phone CSS
import 'react-international-phone/style.css';

// CSS
import './index.css';

// Services
import {
    yellowLight, yellowMain, yellowDark,
    magentaLight, magentaMain, magentaDark,
    blueLight, blueMain, blueDark,
} from 'services/constants';

// Main Component
import App from './app';

// Translation files
import translationFR from 'assets/translations/fr.translations.json';
import translationFR_FALC from 'assets/translations/fr-falc.translations.json';
import translationEN from 'assets/translations/en.translations.json';
import translationDE from 'assets/translations/de.translations.json';
import translationSL from 'assets/translations/sl.translations.json';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- I18NEXT --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

i18next.use(initReactI18next).init({
    resources: {
        "fr": { translation: translationFR },
        "fr-FALC": { translation: translationFR_FALC },
        "en": { translation: translationEN },
        "de": { translation: translationDE },
        "sl": { translation: translationSL },
    },
    lng: 'fr',
    fallbackLng: {
        'fr-FALC': ['fr'],
        default: ['en'],
    },
});

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MUI COMPONENTS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Badge' {
    interface BadgePropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MUI PALETTE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

declare module '@mui/material/styles' {
    interface Palette {
        blue: Palette['primary'];
        magenta: Palette['primary'];
        yellow: Palette['primary'];
    }
    interface PaletteOptions {
        blue: PaletteOptions['primary'];
        magenta: PaletteOptions['primary'];
        yellow: PaletteOptions['primary'];
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MUI THEME --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const theme = createTheme({
    palette: {
        blue: {
            light: blueLight,
            main: blueMain,
            dark: blueDark,
            contrastText: '#fff',
        },
        magenta: {
            light: magentaLight,
            main: magentaMain,
            dark: magentaDark,
            contrastText: '#fff',
        },
        yellow: {
            light: yellowLight,
            main: yellowMain,
            dark: yellowDark,
            contrastText: '#fff',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
                InputProps: {
                    style: {
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    minWidth: 0,
                },
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    zIndex: 0,
                    minWidth: 0,
                    minHeight: 0,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterDelay: 400,
                leaveDelay: 200,
                placement: 'bottom',
            },
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: 'rgba(255, 0, 80, 0.1)',
                        "&:hover": {
                            backgroundColor: 'rgba(255, 0, 80, 0.1)',
                        },
                    },
                },
            },
        },
    },
});

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- ROOT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <HelmetProvider>  
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </HelmetProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </ApolloProvider>
);
