import { CircularProgress } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SPINNER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Spinner() {
    return (
        <div
            className="flex-direction-column"
            style={{ flex: 1, padding: 24 }}
        >
            <CircularProgress />
        </div>
    );
}
