import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

// i18next
import i18next from 'i18next';

// Storage
import { getStoredToken, clearStorage } from './storage';

/* * *
* API URL
*/

export var API_URL : string;

switch (window.origin) {
    
    case 'http://localhost:3000':
    case 'http://localhost:3001':
        API_URL = 'http://127.0.0.1:8000/';
        break;

    default:
        API_URL = window.origin + '/';
        break;
};

/* * *
* ZEROBARRIER URL
*/

export var ZEROBARRIER_URL : string;
switch (window.origin) {

    case 'http://localhost:3000':
        ZEROBARRIER_URL = 'http://localhost:3001/';
        break;

    case 'http://localhost:3001':
        ZEROBARRIER_URL = 'http://localhost:3000/';
        break;

    case 'https://beta.happinco.app':
        ZEROBARRIER_URL = 'https://beta.v2.zerobarrier.eu/';
        break;

    case 'https://demo.happinco.app':
        ZEROBARRIER_URL = 'https://demo.zerobarrier.app/';
        break;

    case 'https://happinco.app':
        ZEROBARRIER_URL = 'https://zerobarrier.app/';
        break;
};

/* * *
* USER GUIDE URL
*/

export var USER_GUIDE_URL : string;

switch (window.origin) {

    case 'http://localhost:3000':
        USER_GUIDE_URL = 'http://localhost:3001/';
        break;

    case 'http://localhost:3001':
        USER_GUIDE_URL = 'http://localhost:3000/';
        break;

    case 'https://happinco.app':
    case 'https://demo.happinco.app':
    case 'https://beta.happinco.app':
        USER_GUIDE_URL = 'https://guide.discovia.fr/';
        break;
}

// --------------------------------------------------------------------------------- \\
// --------------------------- APOLLO UPLOAD CLIENT -------------------------------- \\
// --------------------------------------------------------------------------------- \\

/* * *
* - Enable to send file on mutation
*/

const uploadLink = createUploadLink({
    uri: API_URL + 'graphql',
});

// --------------------------------------------------------------------------------- \\
// --------------------------------- AUTH LINK ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const authLink = setContext((_, { headers }) => {

    const token = getStoredToken();

    return {
        headers: {
            ...headers,
            'authorization': token ? 'Bearer ' + token : '',
            'x-localization': i18next.language,
            'x-application': 'happinco',
        },
    };
});

// --------------------------------------------------------------------------------- \\
// --------------------------------- ERROR LINK ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

const errorLink = onError(({ graphQLErrors, networkError }) => {

    if (graphQLErrors) {
        
        for (let err of graphQLErrors) {

            console.error(`[GraphQL error]: ${err.message}`);

            if (err.message !== '' && err.message.includes('Unauthenticated')) {
                clearStorage();
                client.clearStore();
                window.location.reload();
            }
        }
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError.message}`);
    }
});

// --------------------------------------------------------------------------------- \\
// ----------------------------------- CLIENT -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authLink, uploadLink]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

export default client;
