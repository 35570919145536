import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Avatar, Tooltip } from '@mui/material';

// Services
import { getStoredUser } from 'services/storage';
import { API_URL } from 'services/apollo-config';

// CSS
import styles from './Header.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- AVATAR ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CustomizedAvatar() {
    
    const { t } = useTranslation();
    const [photoUrl, setPhotoUrl] = useState(getStoredUser()?.photoUrl);

    useEffect(() => {

        const onUpdateAvatar = () => {
            setPhotoUrl(getStoredUser()?.photoUrl);
        };
        document.addEventListener('updateAvatar', onUpdateAvatar);

        return () => {
            document.removeEventListener('updateAvatar', onUpdateAvatar);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip title={t('profile')}> 
            <IconButton
                component={Link}
                to="/app/profile"
            >
                <Avatar
                    src={photoUrl ? (API_URL + photoUrl) : undefined}
                    className={styles.avatar}
                />
            </IconButton>
        </Tooltip>
    );
}
