import { Routes, Route } from 'react-router-dom';

// Components
import AppLayout from 'components/app-layout';
import Cookies from 'components/cookies';

// Routes
import PrivateRoutes from 'routes/private-routes';
import PublicRoutes from 'routes/public-routes';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ APP ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function App() {
    return (
        <>
            <Cookies />
            <AppLayout>
                <Routes>
                    <Route path="/app/*" element={<PrivateRoutes />} />
                    <Route path="/*" element={<PublicRoutes />} />
                </Routes>
            </AppLayout>
            <div className="linearGradientBackground" />
        </>
    );
}
