import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// CSS
import styles from './Footer.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- FOOTER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Footer({ authenticated = false } : { authenticated: boolean }) {

    const { t } = useTranslation();

    return (
        <footer className={styles.container}>
            <span>
                Copyright © 2022-{new Date().getFullYear()} Discovia. {t('all_rights_reserved')}
            </span>
            {!authenticated && (
                <Link
                    to="/legal-notice"
                    className={styles.legalNotice}
                >
                    {t('legal_notice')}.
                </Link>
            )}
        </footer>
    );
}
