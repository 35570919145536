import { lazy } from 'react';
import { Routes, Route, Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import { getPermissions } from 'services/storage';

// Views
import { RequirePerm, LearnerRestriction } from '../index';
import { useDocumentTitle } from 'services/hooks';

/* * *
* Lazy Imports
*/

// Internships
const Internships = lazy(() => import('../../../views/authenticated/internships'));
const Internship = lazy(() => import('../../../views/authenticated/internships/internship'));
const CreateInternship = lazy(() => import('../../../views/authenticated/internships/create-internship'));
const CreateInternships = lazy(() => import('../../../views/authenticated/internships/create-internships'));
const PreUpdateInternship = lazy(() => import('../../../views/authenticated/internships/update-internship/pre-internship'));
const PostUpdateInternship = lazy(() => import('../../../views/authenticated/internships/update-internship/post-internship'));

// Questionnaires
const Questionnaire = lazy(() => import('../../../views/authenticated/questionnaires/questionnaire'));
const CreateQuestionnaire = lazy(() => import('../../../views/authenticated/questionnaires/create-questionnaire'));
const UpdateQuestionnaire = lazy(() => import('../../../views/authenticated/questionnaires/update-questionnaire'));

// Attendances
const Attendances = lazy(() => import('../../../views/authenticated/attendances'));
const GroupedAttendanceSignatures = lazy(() => import('../../../views/authenticated/attendances/grouped-attendance-signatures'));
const GroupedAttendanceSignature = lazy(() => import('../../../views/authenticated/attendances/grouped-attendance-signatures/grouped-attendance-signature'));
const CreateGroupedAttendanceSignature = lazy(() => import('../../../views/authenticated/attendances/grouped-attendance-signatures/create-grouped-attendance-signature'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- INTERNSHIP ROUTES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function InternshipRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<RequirePerm perm={permissions.includes('internships.viewAny')}><Internships /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={permissions.includes('internships.create')}><CreateInternship /></RequirePerm>} />
            <Route path="create-from-model" element={<RequirePerm perm={permissions.includes('internships.create')}><CreateInternships /></RequirePerm>} />
            <Route path=":id" element={<InternshipRoute />}>
                <Route index element={<RequirePerm perm={permissions.includes('internships.view')}><Internship /></RequirePerm>} />
                <Route path="pre-update" element={<RequirePerm perm={permissions.includes('internships.update')}><PreUpdateInternship /></RequirePerm>} />
                <Route path="post-update" element={<RequirePerm perm={permissions.includes('internships.update')}><PostUpdateInternship /></RequirePerm>} />
                <Route path="create-questionnaire" element={<CreateQuestionnaire />} />
                <Route path=":questionnaireId">
                    <Route index element={<Questionnaire />} />
                    <Route path="update-questionnaire" element={<UpdateQuestionnaire />} />
                </Route>
                <Route path="attendances" element={<Attendances />} />
                <Route path="attendances/group-signatures" element={<LearnerRestriction><GroupedAttendanceSignatures /></LearnerRestriction>} />
                <Route path="attendances/group-signatures/create" element={<LearnerRestriction><CreateGroupedAttendanceSignature /></LearnerRestriction>} />
                <Route path="attendances/group-signatures/:groupedAttendanceSignatureId" element={<LearnerRestriction><GroupedAttendanceSignature /></LearnerRestriction>} />
            </Route>
        </Routes>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- INTERNSHIP ROUTE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function InternshipRoute() {

    const { t } = useTranslation();
    const { id } = useParams();

    useDocumentTitle(`${t('internship.short')} #${id}`);

    return <Outlet />;
}
