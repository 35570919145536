import { useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Apollo
import { useQuery } from '@apollo/client';
import { LOGIN_CODE } from './gql-login-code';

// Services
import { EDestinationApp } from 'services/enums';
import { getErrorExtensions } from 'services/helpers';
import { ZEROBARRIER_URL, USER_GUIDE_URL } from 'services/apollo-config';

// Components
import Backdrop from 'components/backdrop';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LOGIN CODE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const LoginCode = ({ url, to, onClose } : { url: string, to: EDestinationApp, onClose: VoidFunction }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { error, data } = useQuery(LOGIN_CODE);

    useEffect(() => {
        if (data?.loginCode) {

            let destination = '';

            switch (to) {

                case EDestinationApp.ZEROBARRIER:
                    destination = ZEROBARRIER_URL;
                    break;

                case EDestinationApp.USER_GUIDE:
                    destination = USER_GUIDE_URL;
            }

            window.open(destination + 'login-with-code?code=' + data.loginCode + '&url=' + url, '_blank');
            onClose();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return <Backdrop />;
};

export default LoginCode;
