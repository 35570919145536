import { lazy } from 'react';
import { Routes, Route, Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import { getPermissions } from 'services/storage';
import { useDocumentTitle } from 'services/hooks';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Management = lazy(() => import('../../../views/authenticated/management'));

// Companies
const Companies = lazy(() => import('../../../views/authenticated/management/companies'));
const Company = lazy(() => import('../../../views/authenticated/management/companies/company'));
const CreateCompany = lazy(() => import('../../../views/authenticated/management/companies/create-company'));
const UpdateCompany = lazy(() => import('../../../views/authenticated/management/companies/update-company'));

// Users
const Users = lazy(() => import('../../../views/authenticated/management/users'));
const User = lazy(() => import('../../../views/authenticated/management/users/user'));
const CreateUser = lazy(() => import('../../../views/authenticated/management/users/create-user'));
const UpdateUser = lazy(() => import('../../../views/authenticated/management/users/update-user'));

// Question Categories
const QuestionCategories = lazy(() => import('../../../views/authenticated/management/question-categories'));
const QuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/question-category'));
const CreateQuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/create-question-category'));
const UpdateQuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/update-question-category'));

// Questions
const Questions = lazy(() => import('../../../views/authenticated/management/questions'));
const Question = lazy(() => import('../../../views/authenticated/management/questions/question'));
const CreateQuestion = lazy(() => import('../../../views/authenticated/management/questions/create-question'));
const UpdateQuestion = lazy(() => import('../../../views/authenticated/management/questions/update-question'));

// Questionnaire Models
const QuestionnaireModels = lazy(() => import('../../../views/authenticated/management/questionnaire-models'));
const QuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/questionnaire-model'));
const CreateQuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/create-questionnaire-model'));
const UpdateQuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/update-questionnaire-model'));

// Internship Models
const InternshipModels = lazy(() => import('../../../views/authenticated/management/internship-models'));
const InternshipModel = lazy(() => import('../../../views/authenticated/management/internship-models/internship-model'));
const CreateInternshipModel = lazy(() => import('../../../views/authenticated/management/internship-models/create-internship-model'));
const UpdateInternshipModel = lazy(() => import('../../../views/authenticated/management/internship-models/update-internship-model'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGEMENT ROUTES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ManagementRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<Management />} />
            {/* -------------------- COMPANIES -------------------- */}
            <Route path="companies">
                <Route index element={<RequirePerm perm={permissions.includes('companies.viewAny')}><Companies /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('companies.create')}><CreateCompany /></RequirePerm>} />
                <Route path=":id" element={<ManagementRoute title="company" id="id" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('companies.view')}><Company /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('companies.update')}><UpdateCompany /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- USERS -------------------- */}
            <Route path="users">
                <Route index element={<RequirePerm perm={permissions.includes('users.viewAny')}><Users /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('users.create')}><CreateUser /></RequirePerm>} />
                <Route path=":id" element={<ManagementRoute title="user" id="id" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('users.view')}><User /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('users.update')}><UpdateUser /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTION CATEGORIES -------------------- */}
            <Route path="question-categories">
                <Route index element={<RequirePerm perm={permissions.includes('questionCategories.viewAny')}><QuestionCategories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questionCategories.create')}><CreateQuestionCategory /></RequirePerm>} />
                <Route path=":id" element={<ManagementRoute title="category" id="id" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('questionCategories.view')}><QuestionCategory /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questionCategories.update')}><UpdateQuestionCategory /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTIONS -------------------- */}
            <Route path="questions">
                <Route index element={<RequirePerm perm={permissions.includes('questions.viewAny')}><Questions /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questions.create')}><CreateQuestion /></RequirePerm>} />
                <Route path=":id" element={<ManagementRoute title="question" id="id" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('questions.view')}><Question /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questions.update')}><UpdateQuestion /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTIONNAIRE MODELS -------------------- */}
            <Route path="questionnaire-models">
                <Route index element={<RequirePerm perm={permissions.includes('questionnaireModels.viewAny')}><QuestionnaireModels /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questionnaireModels.create')}><CreateQuestionnaireModel /></RequirePerm>} />
                <Route path=":id" element={<ManagementRoute title="model" id="id" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('questionnaireModels.view')}><QuestionnaireModel /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questionnaireModels.update')}><UpdateQuestionnaireModel /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- INTERNSHIP MODELS -------------------- */}
            <Route path="internship-models">
                <Route index element={<RequirePerm perm={permissions.includes('internshipModels.viewAny')}><InternshipModels /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('internshipModels.create')}><CreateInternshipModel /></RequirePerm>} />
                <Route path=":internshipModelId" element={<ManagementRoute title="model" id="internshipModelId" />}>
                    <Route index element={<RequirePerm perm={permissions.includes('internshipModels.view')}><InternshipModel /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('internshipModels.update')}><UpdateInternshipModel /></RequirePerm>} />
                </Route>
            </Route>
        </Routes>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- MANAGEMENT ROUTE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function ManagementRoute({ title, id } : { title: string, id: string }) {

    const { t } = useTranslation();
    const params = useParams();

    useDocumentTitle(`${t(title)} #${params[id]}`);

    return <Outlet />;
}
