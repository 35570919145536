import { lazy } from 'react';
import { Route, Navigate, useLocation, useMatch, Routes } from 'react-router-dom';

// Services
import { clearStorage, getStoredToken } from 'services/storage';
import client from 'services/apollo-config';

// Views
import UnauthenticatedLayout from 'views/unauthenticated';

// Components
import PageNotFound from 'components/page-not-found';

/* * *
* Lazy Imports
*/

const Login = lazy(() => import('../../views/unauthenticated/login'));
const PasswordForgot = lazy(() => import('../../views/unauthenticated/password-forgot'));
const SetNewPassword = lazy(() => import('../../views/unauthenticated/set-new-password'));
const ApiResponse = lazy(() => import('../../views/unauthenticated/api-response'));
const LoginWithCode = lazy(() => import('../../views/unauthenticated/login-with-code'));
const LegalNotice = lazy(() => import('../../views/unauthenticated/legal-notice'));

/* * *
* Redirect to App
*/

const RedirectToApp = ({ children } : { children: JSX.Element }) => {

    const token = getStoredToken();
    const wantLoginWithCode = useMatch('login-with-code');

    if (wantLoginWithCode) {

        clearStorage();
        client.clearStore();

        return children;
    }
    if (token) {
        return <Navigate replace to="/app" />;
    }
    return children;
};

/* * *
* Redirect to Questionnaire
*/

const RedirectToQuestionnaire = () => {

    const token = getStoredToken();
    const { search } = useLocation();
    const questionnaireId = new URLSearchParams(search).get('id');

    if (token) {
        return <Navigate replace to={`/app/questionnaires/${questionnaireId}`} />;
    }
    return <Navigate replace to="/" state={{ questionnaireId }} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PUBLIC ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PublicRoutes() {
    return (
        <Routes>
            <Route element={<RedirectToApp><UnauthenticatedLayout /></RedirectToApp>}>
                <Route index element={<Login />} />
                <Route path="password-forgot" element={<PasswordForgot />} />
                <Route path="set-new-password" element={<SetNewPassword />} />
                <Route path="api-response" element={<ApiResponse />} />
                <Route path="login-with-code" element={<LoginWithCode />} />
                <Route path="legal-notice" element={<LegalNotice />} />
                <Route path="questionnaire" element={<RedirectToQuestionnaire />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}
