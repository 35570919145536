import { lazy } from 'react';
import { Routes, Route, Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import { getPermissions } from 'services/storage';
import { useDocumentTitle } from 'services/hooks';
import { getDynamicLabelKey } from 'services/helpers';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Users = lazy(() => import('../../../views/authenticated/management/users'));
const User = lazy(() => import('../../../views/authenticated/management/users/user'));
const CreateUser = lazy(() => import('../../../views/authenticated/management/users/create-user'));
const UpdateUser = lazy(() => import('../../../views/authenticated/management/users/update-user'));

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- LEARNER ROUTES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function LearnerRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<RequirePerm perm={permissions.includes('users.viewAny')}><Users /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={permissions.includes('users.create')}><CreateUser /></RequirePerm>} />
            <Route path=":id" element={<LearnerRoute />}>
                <Route index element={<RequirePerm perm={permissions.includes('users.view')}><User /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={permissions.includes('users.update')}><UpdateUser /></RequirePerm>} />
            </Route>
        </Routes>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- LEARNER ROUTE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function LearnerRoute() {

    const { t } = useTranslation();
    const { id } = useParams();

    useDocumentTitle(`${t(getDynamicLabelKey('learner'))} #${id}`);

    return <Outlet />;
}
