import { useState } from 'react';
import { Link } from 'react-router-dom';

// MUI
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

// Assets
import LogoHappinco from 'assets/images/logo-happinco-white.png';

// Components
import Menu from '../menu';
import Avatar from './avatar';

// CSS
import styles from './Header.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- HEADER ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Header({ authenticated } : { authenticated: boolean }) {

    const [openMenu, setOpenMenu] = useState(false);

    return (
        <>
            <Grid
                container alignItems="center"
                className={styles.container}
            >
                {authenticated && (
                    <div className={styles.sideItem}>
                        <Tooltip title="Menu">
                            <IconButton onClick={() => setOpenMenu(true)}>
                                <MenuIcon className={styles.menuIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                <Grid
                    item xs
                    container justifyContent="center"
                >
                    <Link to={authenticated ? '/app' : '/'}>
                        <img
                            alt="Logo de l'application Happy In Company"
                            src={LogoHappinco}
                            width={178}
                            height={48}
                            loading="eager"
                        />
                    </Link>
                </Grid>
                {authenticated && (
                    <div className={styles.sideItem}>
                        <Avatar />
                    </div>
                )}
            </Grid>
            {/* -------------------- MENU -------------------- */}
            <Menu
                open={openMenu}
                onClose={() => setOpenMenu(false)}
            />
        </>
    );
}
