import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

/* * *
* Lazy Imports
*/

const Questionnaires = lazy(() => import('../../../views/authenticated/questionnaires'));
const Questionnaire = lazy(() => import('../../../views/authenticated/questionnaires/questionnaire'));

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- QUESTIONNAIRE ROUTES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function QuestionnaireRoutes() {
    return (
        <Routes>
            <Route index element={<Questionnaires />} />
            <Route path=":questionnaireId" element={<Questionnaire />} />
        </Routes>
    );
}
