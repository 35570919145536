
// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DESTINATION APP ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EDestinationApp {
    ZEROBARRIER = 'ZEROBARRIER',
    USER_GUIDE = 'USER_GUIDE',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- GENDERS ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EGender {
    UNKNOWN = 'UNKNOWN',
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- NOTE ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERating {
    DO_NOT_KNOW = 'DO_NOT_KNOW',
    VERY_DISSATISFIED = 'VERY_DISSATISFIED',
    DISSATISFIED = 'DISSATISFIED',
    SATISFIED = 'SATISFIED',
    VERY_SATISFIED = 'VERY_SATISFIED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- NOTIFICATION TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ENotificationType {
    ALL = 'ALL',
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    NONE = 'NONE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- QUESTIONNAIRE MODEL FILTER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EQuestionnaireModelFilter {
    OWNED = 'OWNED',
    SHARED = 'SHARED',
    ALL = 'ALL',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- INTERNSHIP STATUS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EInternshipStatus {
    DRAFT = 'DRAFT',
    WAITING = 'WAITING',
    VALIDATED = 'VALIDATED',
    STOPPED = 'STOPPED',
    CANCELLED = 'CANCELLED',
    REFUSED = 'REFUSED',
    ENDED = 'ENDED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- INTERNSHIP FILTER ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EBelongFilter {
    MINE = 'MINE',
    ALL = 'ALL',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- USER TYPE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EUserRole {
    MEDIATOR = 'MEDIATOR',
    TRAINER = 'TRAINER',
    LEARNER = 'LEARNER',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- INTERNSHIP REQUEST STATUS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EInternshipRequestStatus {
    SENDING = 'SENDING',
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
    REFUSED = 'REFUSED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- INTERNSHIP EXTREMITY -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EInternshipExtremity {
    START = 'START',
    END = 'END',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- STOPPED REASON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EStoppedReason {
    ABORT = 'ABORT',
    SICK_LEAVE = 'SICK_LEAVE',
    SERIOUS_MISCONDUCT = 'SERIOUS_MISCONDUCT',
    HEAVY_MISTAKE = 'HEAVY_MISTAKE',
    PERSONAL_PROBLEM = 'PERSONAL_PROBLEM',
    INCOMPATIBILITY = 'INCOMPATIBILITY',
    OTHER = "OTHER",
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- REQUIREMENT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERequirement {
    NONE = 'NONE',
    LOOK = 'LOOK',
    ACTION = 'ACTION',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- POST INTERNSHIP RESULT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EPostInternshipResult {
    NONE = 'NONE',
    POSITIVE = 'POSITIVE',
    TRANSITION = 'TRANSITION',
    SUSTAINABLE = 'SUSTAINABLE',
    SUSTAINABLE_NO_END = 'SUSTAINABLE_NO_END',
    PROFESSIONAL_PROJECT_VALIDATED = 'PROFESSIONAL_PROJECT_VALIDATED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ STAMP --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EStamp {
    FAILED = '0',
    PARTIALLY = '1',
    VALIDATED = '2',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DESCRIPTOR TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EDescriptorType {
    KNOW_HOW = 'KNOW_HOW',
    KNOWLEDGE = 'KNOWLEDGE',
    APTITUDE = 'APTITUDE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- MASTERY --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EMastery {
    VALIDATED = 'VALIDATED',
    NOT_VALIDATED = 'NOT_VALIDATED',
    NOT_OBSERVABLE = 'NOT_OBSERVABLE',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TIME FILTER ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ETimeFilter {
    PRESENT = 'PRESENT',
    PAST = 'PAST',
    FUTURE = 'FUTURE',
    ALL = 'ALL',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- REPOSITORY TYPE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERepositoryType {
    CUSTOM = 'CUSTOM',
    ACTIVITY = 'ACTIVITY',
    ATYPICAL = 'ATYPICAL',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- STEP TYPE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EStepType {
    SELF_PLACEMENT = 'SELF_PLACEMENT',
    PLACEMENT = 'PLACEMENT',
    PREPARATORY_SEQUENCE = 'PREPARATORY_SEQUENCE',
    WORK_SITUATION = 'WORK_SITUATION',
    REFLECTION_PHASE = 'REFLECTION_PHASE',
    EVALUATION = 'EVALUATION',
    FINAL_EVALUATION = 'FINAL_EVALUATION',
    SUPPORT = 'SUPPORT',
    OTHER_TRAINING = 'OTHER_TRAINING',
    INTERNSHIP = 'INTERNSHIP',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EVALUATION TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EEvaluationType {
    PLACEMENT = 'PLACEMENT',
    EVALUATION = 'EVALUATION',
    FINAL_EVALUATION = 'FINAL_EVALUATION',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REPOSITORY ITEM TYPE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERepositoryItemType {
    CUSTOM_SKILL = 'CUSTOM_SKILL',
    DESCRIPTOR = 'DESCRIPTOR',
    ACTIVITY = 'ACTIVITY',
    TASK = 'TASK',
    ATYPICAL_ITEM = 'ATYPICAL_ITEM',
    LINK_ITEM = 'LINK_ITEM',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ABSENCE REASON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EAbsenceReason {
    SICK_LEAVE = 'SICK_LEAVE',
    PERSONAL_ISSUES = 'PERSONAL_ISSUES',
    UNKNOWN = 'UNKNOWN',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SIGNATURE FREQUENCY --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESignatureFrequency {
    WEEKLY = 'WEEKLY',
    DAILY = 'DAILY',
    EVERY = 'EVERY',
    MIXED = 'MIXED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- APPLICATION ACCESS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EApplicationAccess {
    HAS_ACCESS = 'HAS_ACCESS',
    DOESNT_HAVE_ACCESS = 'DOESNT_HAVE_ACCESS',
    ACCESS_EXPIRES_SOON = 'ACCESS_EXPIRES_SOON',
    ACCESS_EXPIRED = 'ACCESS_EXPIRED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ LIFE HABIT TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ELifeHabitType {
    DAILY_ACTIVITY = 'DAILY_ACTIVITY',
    SOCIAL_ROLE = 'SOCIAL_ROLE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- LIFE HABIT OBSTACLE TYPE ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ELifeHabitObstacleType {
    EMERGENCY = 'EMERGENCY',
    ISSUE = 'ISSUE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ LIFE HABIT OBSTACLE STATUS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ELifeHabitObstacleStatus {
    DRAFT = 'DRAFT',
    CONFIRMED = 'CONFIRMED',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- SEVERITY --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESeverity {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    EXTREME = 'EXTREME',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- CONTACT TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EContactType {
    OTHER = 'OTHER',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ REPORT FREQUENCY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EReportFrequency {
    WEEKLY = 'WEEKLY',
    BIMONTHLY = 'BIMONTHLY',
    MONTHLY = 'MONTHLY',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- REPORT TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EReportType {
    ALERT = 'ALERT',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- REPORT STATUS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EReportStatus {
    WAITING = 'WAITING',
    HANDLED = 'HANDLED',
    SATISFIED = 'SATISFIED',
    NOT_SATISFIED = 'NOT_SATISFIED',
    NO_ISSUE = 'NO_ISSUE',
}
